import type { LoaderFunctionArgs } from 'react-router';
import { redirect } from 'react-router';
import { Outlet } from 'react-router';
import { getMe as getOwnerMe } from '@/api_clients/owners/owner_client';
import { getMe } from '@/api_clients/trainers/user_client';
import { authenticate } from '@/utils/session.server';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { idToken } = await authenticate(request);
  const me = await getMe(idToken).catch((error) => {
    console.error(error);
    return null;
  });
  if (!me) {
    const ownerMe = await getOwnerMe(idToken);
    if (ownerMe) {
      const ownersUrl = process.env.OWNERS_URL ?? 'https://owners.equtum.dev';
      return redirect(ownersUrl);
    }
    return redirect('/login');
  }

  return null;
};

export default function TrainersAuthed() {
  return <Outlet />;
}
